<template>
	<div class="boxx">
		<div ref="bar" class="bar"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			var box = this.$refs.bar
			this.chart(box)
		},
		methods: {
			chart(box) {
				var myChart = this.$echarts.init(box);
				var option;
				option = {
					xAxis: {
						type: 'category',
						data: ['温州宏达激光图像有限公司', '温州市金田塑业有限公司', '苍南县东南印业有限公司', '温州宝驰橡塑有限公司', '温州市厚立包装有限公司','温州中港印务有限公司'],
						axisLabel: {
							interval: 0,
							formatter: function(val) {
							 var strs = val.split(''); //字符串数组
							 var str = ''
							 for(var i = 0, s; s = strs[i++];) { //遍历字符串数组
							 str += s;
							 if(!(i % 6)) str += '\n'; //按需要求余
							 }
							 return str
							}
						}
					},
					grid: {
						bottom: "20%"
					},
					tooltip: {
					    trigger: 'axis',
					    axisPointer: {
							type: 'cross'
					    }
					},
					legend: {
					    data: ['信用分值', '供应商评价','质量']
					},
					yAxis: [{
							type: 'value',
							name: "",
							position: "left",
							max:100
						},
						{
							type: 'value',
							name: "单位：分",
							position: "right",
							max:100
						}
					],
					series: [{
						name:'信用分值',
						data: [50, 60, 70, 70,85,80],
						type: 'line',
						symbolSize: 5,
						symbol: "circle",
						itemStyle: {
							normal:{
								color:"#8080FF",
								lineStyle:{
									color:'#8080FF'
								}
							}
						},
						label: {
							show: true,
							position: "top",
							color: "#333"
						},
						barWidth: 18
					},
					{
						name:'供应商评价',
						data: [20, 30, 30, 40,30,48],
						type: 'line',
						yAxisIndex:1,
						symbolSize: 5,
						symbol: "circle",
						itemStyle: {
							normal:{
								color:"#F59A23",
								lineStyle:{
									color:'#F59A23'
								}
							}
						},
						label: {
							show: true,
							position: "top",
							color: "#333"
						},
						barWidth: 18
					},
					{
						name:'质量',
						data: [30, 40, 60, 40,70,40],
						type: 'line',
						yAxisIndex:1,
						symbolSize: 5,
						symbol: "circle",
						itemStyle: {
							normal:{
								color:"#ABCEEF",
								lineStyle:{
									color:'#ABCEEF'
								}
							}
						},
						label: {
							show: true,
							position: "top",
							color: "#333"
						},
						barWidth: 18
					}
					],
					// dataZoom: [{
					//   type: 'slider',
					//   xAxisIndex: 0,
					//   bottom: 0,
					//   height: 12,
					//   showDetail: false,
					//   startValue: 0,
					//   endValue: 5,
					//   handleSize: '110%',
					//   handleStyle: {
					//     color: "#d3dee5",
					//   },
					//   textStyle: {
					//     color: "#fff"
					//   },
					//   borderColor: "#90979c"
					// }, ],
					title: {
						show: 'true',
						text: '企业信用评价',
						textStyle: {
							color: "#333333",
							fontSize: 18,
						}
					},
				};
				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx {
		width: 100%;
		height: 100%;
		margin-top: 20px;
		.bar {
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}
</style>



