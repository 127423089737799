<template>
	<div id="box">
		<div class="coordination">
			<div class="top2">
				<div class="tlift">
					<potlift></potlift>
				</div>
				<div class="tright">
					<potright></potright>
				</div>
			</div>
			<div class="bom2">
				<div class="blift">
					<poblift></poblift>
				</div>
				<div class="bright">
					<pobright></pobright>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import potlift from "@/components/coordination/potlift.vue"
	import potright from "@/components/coordination/potright.vue"
	import poblift from "@/components/coordination/poblift.vue"
	import pobright from "@/components/coordination/pobright.vue"
	export default {
		components: {
			potlift,
			potright,
			
		}
	}
</script>

<style scoped="scoped" lang="less">
	.top2 {
		width: 100%;
		height: 290px;
		// background-color: #FFFFFF;
		margin-top: 20px;
		display: flex;
		justify-content: space-around;

		.tlift {
			width: 48%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}

		.tright {
			width: 48%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}

	.bom2 {
		width: 100%;
		height: 290px;
		// background-color: #FFFFFF;
		margin-top: 60px;
		display: flex;
		justify-content: space-around;

		.blift {
			width: 48%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}

		.bright {
			width: 48%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}
</style>
