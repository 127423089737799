<template>
	<div class="boxx">
		<div ref="bar" class="bar"></div>
		<el-select class="nian" v-model="nian" placeholder="请选择">
		    <el-option
		      v-for="item in nianArrr"
		      :key="item.value"
		      :label="item.label"
		      :value="item.value">
		    </el-option>
		</el-select>
		<el-select class="yue" v-model="yue" placeholder="请选择">
		    <el-option
		      v-for="item in yueArr"
		      :key="item.value"
		      :label="item.label"
		      :value="item.value">
		    </el-option>
		</el-select>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				nian:0,
				yue:0,
				nianArrr:[
					{value:0,label:'2021年'},
					{value:1,label:'2020年'},
					{value:2,label:'2019年'},
					{value:3,label:'2018年'},
					{value:4,label:'2017年'}
				],
				yueArr:[
					{value:0,label:'1月'},{value:1,label:'2月'},{value:2,label:'3月'},
					{value:3,label:'4月'},{value:4,label:'5月'},{value:5,label:'6月'},
					{value:6,label:'7月'},{value:7,label:'8月'},{value:8,label:'9月'},
					{value:9,label:'10月'},{value:10,label:'11月'},{value:11,label:'12月'}
				]
			}
		},
		mounted() {
			var box = this.$refs.bar
			this.chart(box)
		},
		methods: {
			chart(box) {
				var myChart = this.$echarts.init(box);
				var option;
				option = {
					xAxis: {
						type: 'category',
						data: ['覆膜', '烫金', '模切', '糊盒'],
					},
					yAxis: [{
							type: 'value',
							name: "",
							position: "lift",
						},
						{
							type: 'value',
							name: "单位：万张",
							position: "right",
							interval: 15,
						}
					],
					grid: {
						bottom: "20%"
					},
					series: [{
						data: [50, 50, 90, 80],
						type: 'bar',
						barWidth: 25,
						itemStyle: {
							color: "#A0FFFF"
						},
						label: {
							show: true,
							position: "top",
							color: "#333"
						},
					}, ],
					tooltip: {
						show: true,
					},
					title: {
						show: 'true',
						text: '盒立方产品定制成交量',
						textStyle: {
							color: "#333333",
							fontSize: 18,
						}
					}
				};
				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx {
		width: 100%;
		height: 100%;
		margin-top: 20px;
		position: relative;
		.bar {
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
		.nian{
			position: absolute;
			top: 0px;
			right:200px;
			width: 100px;
			height: 30px;
			.el-input>.el-input__inner{
				height: 30px !important;
				line-height: 30px;
			}
		}
		.yue{
			border-radius: 50px;
			position: absolute;
			top: 0px;
			right:110px;
			width: 80px;
			height: 30px;
			.el-input>.el-input__inner{
				height: 30px !important;
				line-height: 30px;
			}
		}
	}
</style>
