import { render, staticRenderFns } from "./pobright.vue?vue&type=template&id=402b3aae&scoped=true&"
import script from "./pobright.vue?vue&type=script&lang=js&"
export * from "./pobright.vue?vue&type=script&lang=js&"
import style0 from "./pobright.vue?vue&type=style&index=0&id=402b3aae&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402b3aae",
  null
  
)

export default component.exports