<template>
  <div class="boxx">
    <div class="bar" id="ppp" ref="pie"></div>
  </div>
</template>

<script>
  import Highcharts from 'highcharts/highstock';
  import Highcharts3D from 'highcharts/highcharts-3d';
  Highcharts3D(Highcharts);
  export default {
    data() {
      return {
        data: [{
            name: '全国百强企业',
            y: 30
          },
          {
            name: '省科技企业',
            y: 30
          },
          {
            name: '高新企业',
            y: 19
          },
          {
            name: '小升规企业',
            y: 12
          },
        ]
      }
    },
    created() {},
    mounted() {
      this.chart(this.data)
    },
    methods: {
      chart(data) {
        var _this = this
        var chart = Highcharts.chart('ppp', {
          title: {
            text: "企业产能分类占比",
            align: "left",
            style: {
              color: "#333",
              fontSize:'18px',
			  fontWeight: 'bold'
            }
          },
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
            backgroundColor: "rgba(0,0,0,0)"
          },
          tooltip: {
            pointFormat: '{point.y}</b>'
          },
          legend: {
            itemStyle: {
              // color: "#fff",
              // fontSize:"0.554165rem",
            },
            zIndex: 100
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 50,
              size: "90%",
              dataLabels: {
                enabled: true,
                useHTML: true,
                crop: false,
                formatter: function(val) {
                  var name = this.point.name.split('')
                  var arr = ""
                  name.forEach((item, i) => {
                    if (i % 7 == 0 && i > 0) {
                      item += "<br>"
                    }
                    arr += item
                  })
                  var str2 = arr
                  return str2 + ':<br>' + this.y + '%'
                },
              },
              showInLegend: true,
            }
          },
          series: [{
            type: 'pie',
            name: '产业政策兑现',
            colors:["#C6B9EC","#FC732E","#5F7BD4","#FFEC32"],
            dataLabels: {
              // color: '#fff',
              style: {
                // fontSize:"0.554165rem"
              },
            },
            data: data,
            events: {
              click: function(event) {
                console.log(event.point.options)
              }
            }
          }]
        });
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;
	margin-top: 20px;
    .bar {
      width: 100%;
      height: 100%;
	  background-color: #FFFFFF;
	  border-radius: 4px;
    }
  }
</style>
